//
// Components
//

// Import Dependencies
@import 'landing';

// Custom Components
.custom-header,
.custom-header__nav-links {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.hero-img {
  width: 100%;

  &-text-box {
    width: 25%;
    padding: .5rem 1rem;
    font-size: 1.1rem;
    line-height: 1.75;
    color: blue;
    background-color: #fff;
    border-radius: 3px;
    transform: translate(20vh, -80vh);
  }
}

.home-info {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 3rem;
  // padding: 3rem 6rem;
  background-color: rgba(194, 225, 255, 0.582); //  rgb(194, 225, 255)

  &__left {
    color: blue;
  }

  &__right {
    font-size: .9rem;
  }
}

.stories {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}

.custom-card {
//  width: 25%;

  img {
    width: 100%;
    border-radius: 3px;
  }

  &__heading {
    margin-top: .5rem;
    font-size: 1.5rem;
    color: blue;
  }

  &__text {
    font-size: .9rem;
  }
}

.highlight {
  text-align: center;
  background-color: rgb(196, 136, 25);
  padding: 2rem;

  h3 {
    color: #fff;
    margin-bottom: 1rem;
  }

  &__content {
    width: 60%;
    margin: auto;
    color: #fff;
  }
}

.contact {
  display: flex;

  &__info {
    padding: 3rem;
    display: flex;
    justify-content: center;
   // width: 50%;
    background-color: rgb(43, 43, 187);
  }

  &__img {
  //  width: 50%;
  }

  &__form {
  //  width: 50%;
  //  padding: 3rem;
  //  background-color: rgb(208, 253, 253);
  }
}
