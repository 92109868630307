:root {
  --aaa-primary: #1079aa;
  --aaa-primary-2: #12568a;
  --aaa-primary-dk: #00365e;
  --aaa-gray-lt: #bbbdbe;
  --aaa-main-background: #d7f0fb;
  --aaa-buton-clr: #fff;
  --aaa-button-highligt: #d19c3a;
  --aaa-button-inverted-light: #fff;
  --aaa-button-inverted-dark: #F5F8FA;
  --aaa-button-grey: #F5F8FA;
  --aaa-button-light-grey: #eff2f5;
  --aaa-accent: #d19c3a;
  --aaa-warning-clr: #FFFFCC;
  --base-font: "Ubuntu";
  --base-fs: clamp(12px, 100%, 16px);
  --base-text-clr: #33496b;
  --font-bold: 500;
}

/* elements */
html {
  scroll-behavior: smooth;
}

p {
  font-weight: 300;
}

body {
  background-color: var(--aaa-main-background);
  background-image: none;
}

/* fonts */
html,
body {
  font-size: var(--base-fs) !important;
}

/* other */
.bg-custom-button {
  background-color: var(--aaa-warning-clr);
  border-color: var(--aaa-warning-clr);
}

.bg-custom-button .btn-close:focus {
  box-shadow: 0 0 0 0.25rem var(--aaa-warning-clr);
}

.card.custom-opacity {
  background-color: rgba(255, 255, 255, 0.8);
}

.card__hero h1 {
  font-size: calc(1.3rem + 0.1vw);
}

.card__hero h1:first-letter {
  font-weight: bold;
  font-size: 2rem;
}

.center-button {
  padding-right: 0 !important;
}

.contact {
  padding-block: 0;
}

.contact__details :where(h3, p) {
  color: #fff;
}

.contact__info {
  background-color: var(--aaa-primary-2);
  position: relative;
  overflow: clip;
}

.contact__info:before {
  content: "";
  width: 300px;
  height: 120%;
  background: var(--aaa-primary-dk);
  position: absolute;
  top: 20px;
  left: -296px;
  -webkit-transform: rotate(-19deg);
          transform: rotate(-19deg);
}

.contact__info:after {
  content: "";
  width: 300px;
  height: 120%;
  background: var(--aaa-primary-dk);
  position: absolute;
  top: -80px;
  right: -300px;
  -webkit-transform: rotate(-19deg);
          transform: rotate(-19deg);
}

.contact__form {
  background-color: var(--aaa-main-background);
}

.cursor-pointer {
  cursor: pointer;
}

.highlight {
  background-color: var(--aaa-accent);
  border-radius: 0;
}

#home .landing-bg {
  background-color: #fff;
}

#home .landing-header {
  border-bottom: 2px solid var(--aaa-primary);
}

#home .landing-header .btn-accent {
  --btn-bg-clr: #1079aa;
  --btn-border-clr: #1079aa;
  --btn-clr: #fff;
}

#home .landing-header .btn-accent:hover {
  --btn-bg-clr: #d19c3a;
  --btn-border-clr: #d19c3a;
  --btn-clr: #fff;
}

.home-info {
  background-color: var(--aaa-main-background);
  color: 'var(--bs-gray-700)';
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.home-info .home-info__left {
  color: var(--aaa-primary);
}

.icon-only {
  color: #fff !important;
}

.icon-circled {
  background: #fff;
  border-radius: 50%;
}

.icon-circled-text {
  color: var(--aaa-primary-dk) !important;
}

.invert-logo {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.landing-dark-bg {
  background-color: var(--aaa-primary-dk);
}

.landing-border {
  border: 1px dashed var(--aaa-primary);
}

.landing-border .separator {
  --sep-style: solid;
  --sep-clr: var(--aaa-primary);
}

.landing-separator {
  border: 1px dashed var(--aaa-gray-lt);
}

.landing-dark-bg .landing-separator {
  border: 1px dashed var(--aaa-primary);
}

.light-branded-bg {
  background-color: var(--aaa-main-background);;
}

.modal {
  --border-size: 1px;
  --border-style: solid;
  --border-clr: $aaa-primary;
  --border: var(--border-size) var(--border-style) var(--border-clr);
}

.modal-header {
  border-bottom: var(--border);
}

.modal-footer {
  border-top: var(--border);
}

.no-access {
  background-color: var(--aaa-main-background);
  border-radius: 0;
}

#paging_holder .btn:not(:last-child), #paging_holder .btn:not(:first-child) {
  margin-inline: 0.5rem;
}

#paging_holder .btn:last-child {
  margin-inline: 0.5rem 0;
}

#paging_holder .btn:first-child {
  margin-inline: 0 0.5rem;
}

.separator {
  display: block;
  height: 0;
  border-bottom: var(--sep-w, 1px) var(--sep-style, dashed) var(--sep-clr, #eff2f5);
}

.stories {
  background-color: #fff;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.stories [data-grid="true"] {
  --gap: 3rem;
}

.stories .custom-card__heading {
  font-size: 1.3rem;
  color: var(--aaa-primary);
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
}

table {
  --tbl-valign: middle;
}

table.table {
  color: var(--base-text-clr);
}

table tr.t-primary {
  color: var(--aaa-primary);
}

table tr td, table tbody tr td span {
  vertical-align: var(--tbl-valign);
}

table tbody tr td, table tbody tr td span {
  font-size: clamp(12px, 85%, 1.3rem);
}

table .actions_column {
  width: clamp(150px, 100%, 300px);
  -webkit-margin-start: auto;
          margin-inline-start: auto;
}

table .actions_column span,
table .actions_column a {
  margin-inline: 0.5rem;
}

.table-text-visible {
  color: var(--bs-active-primary);
}

.table-text-highlight {
  color: var(--aaa-accent);
}

.t-primary {
  color: var(--aaa-primary);
}

.text_visible {
  color: #009ef7 !important;
}

.warning-banner {
  background-color: var(--aaa-warning-clr);
  padding: 0.5%;
  margin-top: 5px;
}

.warning-triangle {
  color: var(--aaa-primary) !important;
}

/* grid */
[data-grid="true"] {
  --display: grid;
  --gap: 1rem;
  --fraction: 1fr;
  --min-w: 250px;
  --ideal-w: 75%;
  --max-w: 600px;
  display: var(--display);
  gap: var(--gap);
  -ms-grid-columns: (minmax(var(--item-w), var(--fraction)))[var];
      grid-template-columns: repeat(var(--fill, auto-fit), minmax(var(--item-w), var(--fraction)));
}

[data-grid="true"][data-grid-item-size="sm"] {
  --item-w: 160px;
}

[data-grid="true"][data-grid-item-size="md"] {
  --item-w: 250px;
}

[data-grid="true"][data-grid-item-size="lg"] {
  --item-w: 300px;
}

[data-grid="true"][data-grid-fill="auto-fill"] {
  --fill: auto-fill;
}

/* button */
.btn-standard {
  --btn-bg-clr: var(--aaa-primary);
  --btn-border-clr: var(--aaa-primary);
  --btn-clr: var(--aaa-buton-clr);
  background-color: var(--btn-bg-clr);
  border-color: var(--btn-border-clr);
  color: var(--btn-clr);
}

.btn-standard i {
  color: var(--btn-clr); 
}

.btn-standard:hover {
  background-color: var(--aaa-button-highligt);
  border-color: var(--aaa-button-highligt);
  color: var(--aaa-buton-clr);
}

.btn-inverted-light {
  background-color: var(--aaa-button-inverted-light);
  border-color: var(--aaa-button-inverted-light);
  color: var(--aaa-primary); 
}

.btn-inverted-light i {
  color: var(--aaa-primary); 
}

.btn-inverted-light:hover {
  background-color: var(--aaa-button-grey);
  border-color: var(--aaa-button-grey);
}

.btn-inverted-light:hover i {
  color: var(--aaa-primary-dk); 
}

.btn-inverted-dark {
  background-color: var(--aaa-button-grey);
  border-color: var(--aaa-button-grey);
}

.btn-inverted-dark i {
  color: var(--aaa-primary); 
}

.btn-inverted-dark:hover {
  background-color: var(--aaa-button-light-grey);
  border-color: var(--aaa-button-light-grey);
}

.btn-inverted-dark:hover i {
  color: var(--aaa-primary-dk); 
}

.btn-hover-inverted {
  background-color: var(--aaa-button-grey);
  border-color: var(--aaa-button-grey);
}

.btn-hover-inverted i {
  color: var(--aaa-primary-dk); 
}

.btn-hover-inverted:hover {
  background-color: var(--aaa-button-light-grey);
  border-color: var(--aaa-button-light-grey);
}

.btn-hover-inverted:hover i {
  color: var(--aaa-primary); 
}

.btn-modal-icon {
  background-color: #fff;
}

.btn-modal-icon:hover {
  background-color: var(--btn-active-light-primary);
}

.btn.btn-light.btn-icon:hover {
  --btn-clr: var(--aaa-primary);
}

.btn.btn-light.btn-icon:hover span,
.btn.btn-light.btn-icon:hover i {
  color: var(--btn-clr);
}

.btn.btn-light.btn-icon.btn-active-light-danger:hover {
  --btn-clr: var(--bs-danger);
}

.btn.btn-light.btn-icon.btn-active-light-danger:hover span,
.btn.btn-light.btn-icon.btn-active-light-danger:hover i {
  color: var(--btn-clr);
}

.btn.btn-white.t-primary {
  color: var(--aaa-primary);
}

.btn.btn-white.t-primary i {
  color: var(--aaa-primary);
}

.btn.btn-white.t-primary:hover {
  color: var(--aaa-accent);
}

.btn.btn-white.t-primary:hover i {
  color: var(--aaa-accent);
}